import React, { useState, useEffect } from "react";
import { 
  Box, Heading, Flex, Text, Table, Thead, Tbody, Tr, Th, Td, Spinner, Divider, Grid, GridItem 
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "../../axiosConfig";
import { InputIcon, ProcessesIcon, ClimateCultureIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

const DEC_HOUSES = 2;

const AreaReport = ({ areaKey, color }) => {
  const { t } = useTranslation();
  const { organizationSurveyId } = useParams();
  const [areaData, setAreaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");


  const iconMap = {
    input: <InputIcon />,
    processes: <ProcessesIcon />,
    climate_and_culture: <ClimateCultureIcon />,
  };

  useEffect(() => {
    const fetchAreaData = async () => {
      try {
        const response = await axios.get(`/survey_reports/${organizationSurveyId}/area_analysis?area=${areaKey}`);
        setAreaData(response.data);
      } catch (err) {
        setError(t("reports.area_report.error"));
      } finally {
        setLoading(false);
      }
    };

    fetchAreaData();
  }, [organizationSurveyId, areaKey, t]);

  if (loading) return <Spinner size="xl" />;
  if (error) return <Text color="red.500">{error}</Text>;

  return (
    <Box>
      <Flex align="center" mb={6}>
        {iconMap[areaKey] || <InputIcon />}
        <Heading as="h1" size="2xl" color={color} ml={3}>{t(areaData.area)}</Heading>
      </Flex>

      <Divider />

      {/* Side-by-side layout for the first two tables */}
      <Grid templateColumns={{ base: "1fr", lg: "repeat(2, minmax(0, 1fr))" }} gap={6} mt={6} alignItems="start">
        {/* Table 1: Stability Index per Page */}
        <GridItem>
          <Heading as="h3" size="lg">{t("reports.area_report.factor_analysis")}</Heading>
          <Table variant="striped" size="sm" mt={4}>
            <Thead>
              <Tr>
                <Th>{t("reports.area_report.table_headers.page")}</Th>
                <Th>{t("reports.area_report.table_headers.stability_index")}</Th>
                <Th>{t("reports.area_report.table_headers.benchmark_stability_index")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {areaData.page_stats.map((page) => (
                <Tr key={page.page}>
                  <Td>{page.page}</Td>
                  <Td>{parseFloat(page.stability_index).toFixed(DEC_HOUSES)}</Td>
                  <Td>{parseFloat(page.benchmark_stability_index).toFixed(DEC_HOUSES)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </GridItem>

        {/* Table 2: Overall Role-Based Stability Index */}
        <GridItem>
          <Heading as="h3" size="lg">{t("reports.area_report.team_analysis")}</Heading>
          <Table size="sm" variant="striped" mt={4} bgColor="white">
            <Thead>
              <Tr>
                <Th>{t("reports.area_report.table_headers.category")}</Th>
                <Th>{t("reports.area_report.table_headers.stability_index")}</Th>
                <Th>{t("reports.area_report.table_headers.benchmark_stability_index")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{t("reports.area_report.table_headers.total")}</Td>
                <Td>{parseFloat(areaData.overall_stats.total.stability_index).toFixed(DEC_HOUSES)}</Td>
                <Td>{parseFloat(areaData.overall_stats.total.benchmark_stability_index).toFixed(DEC_HOUSES)}</Td>
              </Tr>
              {areaData.overall_stats.roles.map((role) => (
                <Tr key={role.role}>
                  <Td>{role.role}</Td>
                  <Td>{parseFloat(role.stability_index).toFixed(DEC_HOUSES)}</Td>
                  <Td>{parseFloat(role.benchmark_stability_index).toFixed(DEC_HOUSES)}</Td>
                </Tr>
              ))}
              <Tr>
                <Td>{t("reports.area_report.table_headers.skolledning")}</Td>
                <Td>{parseFloat(areaData.overall_stats.skolledning.stability_index).toFixed(DEC_HOUSES)}</Td>
                <Td>{parseFloat(areaData.overall_stats.skolledning.benchmark_stability_index).toFixed(DEC_HOUSES)}</Td>
              </Tr>
              <Tr>
                <Td>{t("reports.area_report.table_headers.personal")}</Td>
                <Td>{parseFloat(areaData.overall_stats.personal.stability_index).toFixed(DEC_HOUSES)}</Td>
                <Td>{parseFloat(areaData.overall_stats.personal.benchmark_stability_index).toFixed(DEC_HOUSES)}</Td>
              </Tr>
              <Tr>
                <Td>{t("reports.area_report.table_headers.difference")}</Td>
                <Td>{parseFloat(areaData.overall_stats.difference.stability_index).toFixed(DEC_HOUSES)}</Td>
                <Td>-</Td>
              </Tr>
            </Tbody>
          </Table>
        </GridItem>
      </Grid>

      <Divider mt={6} borderColor="white" />

      {/* Full-Width Table 3: Page-Based Role Stability Analysis */}
      <Heading as="h3" size="lg" mt={6}>{t("reports.area_report.team_analysis_per_factor")}</Heading>
      <Table size="sm" variant="striped" mt={4} bgColor="white">
        <Thead>
          <Tr>
            <Th>{t("reports.area_report.table_headers.role")}</Th>
            {areaData.page_stats.map((page) => (
              <Th key={page.page} colSpan={2}>{page.page}</Th>
            ))}
          </Tr>
          <Tr>
            <Th></Th>
            {areaData.page_stats.map(() => (
              <>
                <Th>{t("reports.area_report.table_headers.stability_index")}</Th>
                <Th>{t("reports.area_report.table_headers.benchmark_stability_index")}</Th>
              </>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {areaData.role_page_stats.map((role) => (
            <Tr key={role.role}>
              <Td>{role.role}</Td>
              {role.pages.map((page) => (
                <>
                  <Td key={`${role.role}-${page.page}-stability_index`}>{parseFloat(page.stability_index).toFixed(DEC_HOUSES)}</Td>
                  <Td key={`${role.role}-${page.page}-benchmark_stability_index`}>{role.role === 'Difference' ? '-' : parseFloat(page.benchmark_stability_index).toFixed(DEC_HOUSES)}</Td>
                </>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default AreaReport;