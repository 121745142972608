import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Select,
  VStack,
  Heading,
  Card,
  CardBody,
  useToast,
  useColorMode,
  useColorModeValue,
  Switch,
  Text,
  HStack,
} from "@chakra-ui/react";
import { useUser } from "../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import { useFeatureToggle } from "../../contexts/FeatureToggleContext";
import { useTranslation } from "react-i18next";

const Preferences = () => {
  const { t } = useTranslation();
  const { settings, updateSettings } = useUser();
  const { toggleColorMode } = useColorMode();
  const [theme, setTheme] = useState(settings.theme);
  const [language, setLanguage] = useState(settings.language);
  const [emailNotifications, setEmailNotifications] = useState(settings.emailNotifications ?? true);
  const { isFeatureEnabled } = useFeatureToggle();
  const toast = useToast();
  const location = useLocation();

  // Feature Toggle: Enable Theme Switching
  const ENABLE_THEME_TOGGLE = isFeatureEnabled("system_theme");

  // Detect if user is in Hub
  const isHubUser = location.pathname.startsWith("/hub");

  useEffect(() => {
    setTheme(settings.theme);
    setLanguage(settings.language);
    setEmailNotifications(settings.emailNotifications ?? true);
  }, [settings]);

  const handleSave = async () => {
    const success = await updateSettings({ theme, language, emailNotifications });

    if (success) {
      toast({
        title: t("preferences.preferences_updated"),
        description: t("preferences.preferences_saved"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      if (ENABLE_THEME_TOGGLE) {
        toggleColorMode();
      }
    } else {
      toast({
        title: "Error",
        description: t("preferences.preferences_error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box mx="auto" p={6} maxW="container.md">
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        {isHubUser ? t("preferences.title_org") : t("preferences.title_user")}
      </Heading>

      <Card bg={useColorModeValue("white", "gray.800")} shadow="md" borderRadius="lg">
        <CardBody>
          <VStack spacing={5} align="stretch">
            {/* Language Selection */}
            <FormControl>
              <FormLabel>{t("preferences.language")}</FormLabel>
              <Select value={language} onChange={(e) => setLanguage(e.target.value)}>
                <option value="en">English</option>
                <option value="sv">Svenska</option>
              </Select>
            </FormControl>

            {/* Theme Selection (Only if Enabled) */}
            {ENABLE_THEME_TOGGLE && (
              <FormControl>
                <FormLabel>{t("preferences.theme")}</FormLabel>
                <Select value={theme} onChange={(e) => setTheme(e.target.value)}>
                  <option value="light">{t("preferences.light_mode")}</option>
                  <option value="dark">{t("preferences.dark_mode")}</option>
                </Select>
              </FormControl>
            )}

            {/* Email Notifications Toggle */}
            <FormControl>
              <HStack justifyContent="space-between">
                <FormLabel mb="0">{t("preferences.email_notifications")}</FormLabel>
                <Switch
                  isChecked={emailNotifications}
                  onChange={() => setEmailNotifications((prev) => !prev)}
                />
              </HStack>
              <Text fontSize="sm" color="gray.500">
                {t("preferences.email_notifications_desc")}
              </Text>
            </FormControl>

            <Divider />

            {/* Save Button */}
            <Button onClick={handleSave} colorScheme="green" size="lg" variant="outline">
              {t("preferences.save_changes")}
            </Button>
          </VStack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default Preferences;