import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
  IconButton,
  HStack,
  Card,
  CardHeader,
  CardBody,
  Spacer,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";

const SurveyForm = ({ onSubmit, onCancel, initialData = {} }) => {
  const [surveyName, setSurveyName] = useState(initialData.name || "");
  const [language, setLanguage] = useState(initialData.language || "");
  const [passcode, setPasscode] = useState(initialData.passcode || "");
  const [endMessage, setEndMessage] = useState(initialData.end_message || "");
  const [groups, setGroups] = useState(
    initialData.survey_groups?.map((group) => ({
      ...group,
      localId: group.id || Date.now() + Math.random(), // Unique ID for each group
    })) || [{ name: "", localId: Date.now() + Math.random() }]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      name: surveyName,
      language,
      passcode,
      end_message: endMessage,
      survey_groups_attributes: groups.map(({ name, id, _destroy }) => ({
        name,
        id,
        _destroy,
      })),
    });
  };

  const handleGroupChange = (localId, value) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.localId === localId ? { ...group, name: value } : group
      )
    );
  };

  const handleAddGroup = () => {
    setGroups((prevGroups) => [
      ...prevGroups,
      { name: "", localId: Date.now() + Math.random() },
    ]);
  };

  const handleRemoveGroup = (localId) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.localId === localId
          ? { ...group, _destroy: group.id ? true : undefined }
          : group
      ).filter((group) => group._destroy !== undefined || !group._destroy)
    );
  };

  const cardBg = useColorModeValue("white", "gray.800");
  const sectionBg = useColorModeValue("gray.50", "gray.700");

  return (
    <Box mx="auto" p={6} maxW="container.md">
      <Card bg={cardBg} shadow="md" borderRadius="lg">
        <CardHeader pb={0} textAlign="center">
          <FormLabel fontSize="xl" fontWeight="bold">
            {initialData.name ? "Edit Survey" : "Create Survey"}
          </FormLabel>
        </CardHeader>

        <CardBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={5} align="stretch">
              {/* Survey Name */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>Survey Name</FormLabel>
                  <Input
                    type="text"
                    value={surveyName}
                    onChange={(e) => setSurveyName(e.target.value)}
                    placeholder="Enter survey name"
                  />
                </FormControl>
              </Box>

              {/* Language Selection */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>Language</FormLabel>
                  <Select
                    placeholder="Select language"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value="english">English</option>
                    <option value="swedish">Swedish</option>
                  </Select>
                </FormControl>
              </Box>

              {/* End Message */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl>
                  <FormLabel>End Message</FormLabel>
                  <Textarea
                    value={endMessage}
                    onChange={(e) => setEndMessage(e.target.value)}
                    placeholder="Enter a message to display after survey completion"
                    size="md"
                  />
                </FormControl>
              </Box>

              {/* Survey Profession (Groups) */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl>
                  <FormLabel>Survey Profession</FormLabel>
                  {groups
                    .filter((group) => !group._destroy) // Only show groups not marked for deletion
                    .map((group) => (
                      <HStack key={group.localId} spacing={3}>
                        <Input
                          placeholder="Enter Profession name"
                          value={group.name}
                          onChange={(e) => handleGroupChange(group.localId, e.target.value)}
                        />
                        <IconButton
                          icon={<FaTrash />}
                          colorScheme="red"
                          size="sm"
                          variant="outline"
                          aria-label="Remove profession"
                          title="Remove profession"
                          onClick={() => handleRemoveGroup(group.localId)}
                        />
                      </HStack>
                    ))}
                  <Button mt={2} onClick={handleAddGroup} variant="outline" colorScheme="blue" size="sm">
                    Add Profession
                  </Button>
                </FormControl>
              </Box>

              {/* Submit & Cancel Buttons */}
              <Flex>
                <Button variant="outline" onClick={onCancel} size="sm">
                  Cancel
                </Button>

                <Spacer />
                
                <Button type="submit" colorScheme="green" size="sm" variant="outline">
                  {initialData.name ? "Update Survey" : "Create Survey"}
                </Button>
              </Flex>
            </VStack>
          </form>
        </CardBody>
      </Card>
    </Box>
  );
};

export default SurveyForm;