import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  VStack,
  Flex,
  HStack,
  Heading,
  Text,
  Divider,
  Spacer,
  Switch,
  Popover,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Icon,
  IconButton
} from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';

const QuestionForm = ({ initialData = {}, onSubmit, onCancel, surveyDetails, groups }) => {
  const [questionText, setQuestionText] = useState(initialData.question_text || '');
  const [questionType, setQuestionType] = useState(initialData.question_type || 'slider');
  const [position, setPosition] = useState(initialData.position || 1);
  const [invertValues, setInvertValues] = useState(initialData.invert_values || false);
  const [area, setArea] = useState(initialData.area || 'input');
  const [customizations, setCustomizations] = useState(
    groups.map((group) => {
      const existingCustomization = initialData.customizations?.find(
        (c) => c.survey_group_id === group.id
      );
      return {
        id: existingCustomization?.id || null,
        surveyGroupId: group.id,
        surveyGroupName: group.name,
        customQuestionText: existingCustomization?.custom_question_text || '',
        visible: existingCustomization?.visible !== false,
      };
    })
  );

  useEffect(() => {
    setCustomizations((prevCustomizations) =>
      groups.map((group) => {
        const existing = prevCustomizations.find((c) => c.surveyGroupId === group.id);
        return (
          existing || {
            id: null,
            surveyGroupId: group.id,
            surveyGroupName: group.name,
            customQuestionText: '',
            visible: true,
          }
        );
      })
    );
  }, [groups]);

  const handleCustomizationChange = (surveyGroupId, field, value) => {
    setCustomizations((prev) =>
      prev.map((customization) =>
        customization.surveyGroupId === surveyGroupId
          ? { ...customization, [field]: value }
          : customization
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      question_text: questionText,
      question_type: questionType,
      position,
      invert_values: invertValues,
      area,
      question_customizations_attributes: customizations.map(
        ({ id, surveyGroupId, customQuestionText, visible }) => ({
          id,
          survey_group_id: surveyGroupId,
          custom_question_text: customQuestionText,
          visible,
        })
      ),
    });
  };

  return (
    <Box mt={4} mb={10}>
      <Heading as="h1" size="lg" mb={4}>
        {surveyDetails.surveyName}
      </Heading>
      <Heading as="h2" size="lg" color="gray.500" mb={6}>
        {surveyDetails.pageTitle}
      </Heading>

      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl isRequired>
            <FormLabel>Question Text</FormLabel>
            <Input
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
              placeholder="Enter the question text"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Question Type</FormLabel>
            <Select
              placeholder="Select question type"
              value={questionType}
              onChange={(e) => setQuestionType(e.target.value)}
            >
              <option value="slider">Slider</option>
              <option value="bullets">Bullets</option>
              <option value="buttons">Buttons</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Position</FormLabel>
            <NumberInput min={1} value={position} onChange={(value) => setPosition(value)}>
              <NumberInputField />
            </NumberInput>
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Invert Values</FormLabel>
            <Switch isChecked={invertValues} onChange={(e) => setInvertValues(e.target.checked)} />
            <Popover>
              <PopoverTrigger>
                <IconButton
                  aria-label="Information about invert values"
                  icon={<Icon as={FaInfoCircle} mr={2} />}
                  size="sm"
                  ml={2}
                  variant="ghost"
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Invert Values</PopoverHeader>
                <PopoverBody>
                  When enabled, this question’s response scale will be reversed (e.g., 1 → 7, 2 → 6).
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Area</FormLabel>
            <Select
              value={area}
              onChange={(e) => setArea(e.target.value)}
            >
              <option value="input">Input</option>
              <option value="processes">Processes</option>
              <option value="culture_and_climate">Culture & Climate</option>
            </Select>
          </FormControl>

          <Divider />

          {customizations.map(({ id, surveyGroupId, surveyGroupName, customQuestionText, visible }) => (
            <Box key={surveyGroupId} p={4} borderWidth={1} borderRadius="md" bg="gray.50">
              <Text fontWeight="bold" mb={2}>
                Team: {surveyGroupName}
              </Text>
              <HStack spacing={4} align="flex-end">
                <FormControl flex="2">
                  <FormLabel>Custom Text</FormLabel>
                  <Input
                    value={customQuestionText}
                    onChange={(e) =>
                      handleCustomizationChange(surveyGroupId, 'customQuestionText', e.target.value)
                    }
                    placeholder={`Custom text for ${surveyGroupName}`}
                  />
                </FormControl>
                <FormControl flex="1">
                  <FormLabel>Visible?</FormLabel>
                  <Select
                    value={visible ? 'yes' : 'no'}
                    onChange={(e) =>
                      handleCustomizationChange(surveyGroupId, 'visible', e.target.value === 'yes')
                    }
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Select>
                </FormControl>
              </HStack>
            </Box>
          ))}

          <Flex justifyContent="flex-end" width="100%">
            <Button colorScheme="gray" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Spacer />
            <Button type="submit" variant="outline" colorScheme="green">
              Save
            </Button>
          </Flex>
        </VStack>
      </form>
    </Box>
  );
};

export default QuestionForm;
