import React from 'react';
import { Box, VStack, Text, Icon, Link as ChakraLink, useColorModeValue, useColorMode } from '@chakra-ui/react';
import { FaTachometerAlt, FaUsers, FaClipboardList, FaEnvelope, FaUser, FaWrench } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const { t } = useTranslation();

  // Dynamically get colors based on the theme (dark or light)
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.700');
  const { colorMode } = useColorMode();
  const logo = colorMode === 'dark' ? 'logo-white-md.png' : 'logo-md.png';

  return (
    <Box
      as="aside"
      width="250px"
      bg={bgColor}
      color={textColor}
      height="100vh"
      p={4}
      position="fixed"
      top="0"
      left="0"
      zIndex="99"
    >
      {/* Company logo at the top */}
      <Box mb={8} textAlign="center">
        <img src={`/images/${logo}`} alt="HKE Learning" style={{ width: '250px' }} />
      </Box>

      <VStack align="start" spacing={4}>
        <ChakraLink 
          href="/app/dashboard" 
          _hover={{ textDecoration: 'none', backgroundColor: hoverBgColor }} 
          fontWeight="bold" 
          p={3} 
          borderRadius="md"
          width="100%" // Ensure full width
        >
          <Box display="flex" alignItems="center">
            <Icon as={FaTachometerAlt} mr={3} />
            <Text>{t('sidebar.dashboard')}</Text>
          </Box>
        </ChakraLink>

        <ChakraLink 
          href="/app/organizations" 
          _hover={{ textDecoration: 'none', backgroundColor: hoverBgColor }} 
          fontWeight="bold" 
          p={3} 
          borderRadius="md"
          width="100%" // Ensure full width
        >
          <Box display="flex" alignItems="center">
            <Icon as={FaUsers} mr={3} />
            <Text>{t('sidebar.organization')}</Text>
          </Box>
        </ChakraLink>

        <ChakraLink 
          href="/app/surveys" 
          _hover={{ textDecoration: 'none', backgroundColor: hoverBgColor }} 
          fontWeight="bold" 
          p={3} 
          borderRadius="md"
          width="100%" // Ensure full width
        >
          <Box display="flex" alignItems="center">
            <Icon as={FaClipboardList} mr={3} />
            <Text>{t('sidebar.surveys')}</Text>
          </Box>
        </ChakraLink>

        <ChakraLink 
          href="/app/messages" 
          _hover={{ textDecoration: 'none', backgroundColor: hoverBgColor }} 
          fontWeight="bold" 
          p={3} 
          borderRadius="md"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <Icon as={FaEnvelope} mr={3} />
            <Text>{t('sidebar.messages')}</Text>
          </Box>
        </ChakraLink>

        <ChakraLink 
          href="/app/users" 
          _hover={{ textDecoration: 'none', backgroundColor: hoverBgColor }} 
          fontWeight="bold" 
          p={3} 
          borderRadius="md"
          width="100%" // Ensure full width
        >
          <Box display="flex" alignItems="center">
            <Icon as={FaUser} mr={3} />
            <Text>{t('sidebar.users')}</Text>
          </Box>
        </ChakraLink>

        <ChakraLink 
          href="/app/devtools" 
          _hover={{ textDecoration: 'none', backgroundColor: hoverBgColor }} 
          fontWeight="bold" 
          p={3} 
          borderRadius="md"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <Icon as={FaWrench} mr={3} />
            <Text>{t('sidebar.devtools')}</Text>
          </Box>
        </ChakraLink>
      </VStack>
    </Box>
  );
};

export default Sidebar;