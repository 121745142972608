import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import { useSurvey } from '../../contexts/SurveyContext';
import SurveyForm from './SurveyForm';
import { Heading, Box } from '@chakra-ui/react';

const SurveyEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState(null);
  const { fetchSurveys } = useSurvey();

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(`/surveys/${id}`);
        setInitialData(response.data);
      } catch (error) {
        console.error('Error fetching survey:', error);
      }
    };
    fetchSurvey();
  }, [id]);

  const handleUpdateSurvey = async (data) => {
    try {
      await axios.put(`/surveys/${id}`, data);
      fetchSurveys(); // Refresh survey list after updating
      navigate(`/app/surveys`); // Redirect back to the survey list
    } catch (error) {
      console.error('Error updating survey:', error);
    }
  };

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>Edit Survey</Heading>
      {initialData ? (
        <SurveyForm onSubmit={handleUpdateSurvey} onCancel={() => navigate(`/app/surveys`)} initialData={initialData} />
      ) : (
        <div>Loading...</div>
      )}
    </Box>
  );
};

export default SurveyEdit;