import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useUser } from "../../contexts/UserContext";

const ProtectedRoute = ({ children, requiredRoles = [] }) => {
  const authToken = Cookies.get("hkeAuthToken");
  const { user, isLoading } = useUser();
  const location = useLocation();

  if (isLoading) {
    return null;
  }

  if (!authToken) {
    return <Navigate to="/login" replace />;
  }

  // Check token expiration
  const tokenExpiryTime = 3600 * 1000;
  const tokenCreationTime = Cookies.get("tokenCreationTime");

  if (Date.now() - tokenCreationTime > tokenExpiryTime) {
    Cookies.remove("hkeAuthToken");
    Cookies.remove("tokenCreationTime");
    return <Navigate to="/login" replace />;
  }
  
  const isHubUser = user?.role === "org_user";
  const isAppUser = ["tech", "admin", "user"].includes(user?.role);
  
  if (!requiredRoles.includes(user?.role)) {
    if (isHubUser && !location.pathname.startsWith("/hub")) {
      return <Navigate to="/hub/dashboard" replace />;
    }

    if (isAppUser && !location.pathname.startsWith("/app")) {
      console.warn(`Redirecting '${user?.role}' to /app/dashboard`);
      return <Navigate to="/app/dashboard" replace />;
    }

    // 🚨 If role is unknown, log them out
    Cookies.remove("hkeAuthToken");
    Cookies.remove("tokenCreationTime");
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;