import React from "react";
import { Box, Flex, Button, HStack, Select, useBreakpointValue } from "@chakra-ui/react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ReportLayout = ({ isHub }) => {
  const { t } = useTranslation();
  const { organizationSurveyId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const basePath = isHub ? `/hub/results/${organizationSurveyId}` : `/app/results/${organizationSurveyId}`;

  // Sections for navigation
  const sections = [
    { path: "report", label: `📊 ${t('reports.layout.report')}` },
    // { path: "stability", label: `📉 ${t('reports.layout.stability_overview')}` },
    { path: "strengths", label: `💪 ${t('reports.layout.strengths')}` },
    { path: "development", label: `🔧 ${t('reports.layout.development')}` },
    { path: "input", label: `📝 ${t('reports.layout.input')}` },
    { path: "processes", label: `⚙️ ${t('reports.layout.processes')}` },
    { path: "climate-culture", label: `🌍 ${t('reports.layout.climate_and_culture')}` },
    // { path: "plc-group", label: `👥 ${t('reports.layout.plc_group')}` },
    // { path: "recommendations", label: `📌 ${t('reports.layout.recommendations')}` },
  ];

  // Responsive behavior
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box p={6} mx="auto">
      {/* Navigation Bar */}
      {isMobile ? (
        // 🔹 Mobile View: Dropdown Menu
        <Select
          bg="gray.100"
          borderRadius="md"
          mb={6}
          onChange={(e) => navigate(`${basePath}/${e.target.value}`)}
          defaultValue={sections.find((s) => location.pathname.includes(s.path))?.path || "report"}
        >
          {sections.map((section) => (
            <option key={section.path} value={section.path}>
              {section.label}
            </option>
          ))}
        </Select>
      ) : (
        // 🔹 Desktop View: Horizontal Scrollable Tabs
        <Flex
          overflowX="auto"
          bg="gray.100"
          borderRadius="md"
          p={3}
          mb={6}
          boxShadow="md"
          justifyContent="center"
          css={{
            "::-webkit-scrollbar": { display: "none" },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          <HStack spacing={4}>
            {sections.map((section) => (
              <Button
                key={section.path}
                variant={location.pathname.includes(section.path) ? "solid" : "outline"}
                colorScheme="blue"
                onClick={() => navigate(`${basePath}/${section.path}`)}
              >
                {section.label}
              </Button>
            ))}
          </HStack>
        </Flex>
      )}

      {/* Report Content */}
      <Box p={6} bg="white" borderRadius="md" shadow="md">
        <Outlet /> {/* This will render the actual report pages inside the layout */}
      </Box>
    </Box>
  );
};

export default ReportLayout;