import React from 'react';
import {
  Flex,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  VStack,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
  useDisclosure,
  useColorModeValue,
  Link as ChakraLink
} from '@chakra-ui/react';
import { HamburgerIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FaUser, FaCogs, FaKey, FaSignOutAlt, FaSlidersH, FaTachometerAlt, FaClipboardList, FaChartPie } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../../../contexts/UserContext';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const createBreadcrumbs = (location, t) => {
  const pathnames = location.pathname.split('/').filter((x) => x && !['hub', 'dashboard', 'edit'].includes(x));
  return pathnames.map((value, index) => {
    const to = `/hub/${pathnames.slice(0, index + 1).join('/')}`;
    return { label: t(`breadcrumbs.${value.toLowerCase()}`, value.charAt(0).toUpperCase() + value.slice(1)), href: to };
  });
};

const OrgNavbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const breadcrumbs = createBreadcrumbs(location, t);
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue('white', 'gray.800');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.700');
  const logo = useColorModeValue('logo-hub-md.png', 'logo-white-md.png');

  const handleLogout = () => {
    Cookies.remove('hkeAuthToken');
    window.location.href = '/login';
  };

  return (
    <Flex as="nav" align="center" justify="space-between" p="1rem" bg={bgColor}>
      {/* Logo - Only Visible on Mobile */}
      <Box display={{ base: 'block', md: 'none' }}>
        <img src={`/images/${logo}`} alt="Company Logo" style={{ width: '100px' }} />
      </Box>

      {/* Breadcrumbs - Hide on Mobile */}
      <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />} display={{ base: 'none', md: 'flex' }}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/hub/dashboard">{t('navbar.dashboard')}</BreadcrumbLink>
        </BreadcrumbItem>
        {breadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbItem key={index}>
            <BreadcrumbLink as={Link} to={breadcrumb.href}>{breadcrumb.label}</BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>

      {/* Burger Menu Button - Only on Mobile (Right Side) */}
      <Box display={{ base: 'block', md: 'none' }}>
        <IconButton
          icon={<HamburgerIcon />}
          aria-label={t('navbar.menu')}
          onClick={onOpen}
          variant="outline"
        />
      </Box>

      {/* User Dropdown - Only on Desktop */}
      {user && (
        <HStack spacing={4} display={{ base: 'none', md: 'flex' }}>
          <Menu>
            <MenuButton as={IconButton} icon={<Icon as={FaUser} />} />
            <MenuList>
              <Box px={4} py={2}>
                <Text fontWeight="bold">{user.first_name} {user.last_name}</Text>
                <Text fontSize="sm" color="gray.500">{user.email}</Text>
              </Box>
              <MenuItem as={Link} to="/hub/profile"><Icon as={FaUser} mr={2} /> {t('navbar.profile')}</MenuItem>
              <MenuItem as={Link} to="/hub/preferences"><Icon as={FaSlidersH} mr={2} /> {t('navbar.preferences')}</MenuItem>
              <MenuItem as={Link} to="/hub/password"><Icon as={FaKey} mr={2} /> {t('navbar.security')}</MenuItem>
              <MenuItem as={Link} to="/hub/settings"><Icon as={FaCogs} mr={2} /> {t('navbar.org_settings')}</MenuItem>
              <MenuItem onClick={handleLogout}><Icon as={FaSignOutAlt} mr={2} /> {t('navbar.logout')}</MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      )}

      {/* Drawer for Mobile Menu (Sidebar + Profile) */}
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t('navbar.menu')}</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              {/* Sidebar Links */}
              <ChakraLink as={Link} to="/hub/dashboard" onClick={onClose}>
                <Icon as={FaTachometerAlt} mr={2} /> {t('sidebar.dashboard')}
              </ChakraLink>
              <ChakraLink as={Link} to="/hub/surveys" onClick={onClose}>
                <Icon as={FaClipboardList} mr={2} /> {t('sidebar.surveys')}
              </ChakraLink>
              <ChakraLink as={Link} to="/hub/results" onClick={onClose}>
                <Icon as={FaChartPie} mr={2} /> {t('sidebar.results')}
              </ChakraLink>

              {/* Separator (Fixing MenuDivider Error) */}
              <Box as="hr" width="100%" borderColor="gray.300" />

              {/* Profile & Logout */}
              <ChakraLink as={Link} to="/hub/profile" onClick={onClose}>
                <Icon as={FaUser} mr={2} /> {t('navbar.profile')}
              </ChakraLink>
              <ChakraLink as={Link} to="/hub/preferences" onClick={onClose}>
                <Icon as={FaSlidersH} mr={2} /> {t('navbar.preferences')}
              </ChakraLink>
              <ChakraLink as={Link} to="/hub/password" onClick={onClose}>
                <Icon as={FaKey} mr={2} /> {t('navbar.security')}
              </ChakraLink>
              <ChakraLink as={Link} to="/hub/settings" onClick={onClose}>
                <Icon as={FaCogs} mr={2} /> {t('navbar.org_settings')}
              </ChakraLink>

              {/* Separator (Fixing MenuDivider Error) */}
              <Box as="hr" width="100%" borderColor="gray.300" />

              {/* Logout */}
              <ChakraLink as="button" onClick={handleLogout}>
                <Icon as={FaSignOutAlt} mr={2} /> {t('navbar.logout')}
              </ChakraLink>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default OrgNavbar;