import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Card,
  CardHeader,
  CardBody,
  Spacer,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axiosConfig";

const OrganizationUserForm = () => {
  const { id, action } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (action === "edit") {
        try {
          const response = await axios.get(`/organizations/${id}/user`);
          const { first_name, last_name, email } = response.data;
          setFormData({ first_name, last_name, email });
        } catch (error) {
          console.error("Error fetching user data:", error);
          toast({
            title: "Error",
            description: "Could not load user details.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    };

    fetchUser();
  }, [action, id, toast]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCancel = () => {
    navigate(`/app/organizations/${id}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const payload = { user: formData };
      await axios.post(`/organizations/${id}/user`, payload);
      toast({
        title: "Success",
        description:
          action === "create"
            ? "User created successfully."
            : "User updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate(`/app/organizations/${id}`);
    } catch (error) {
      console.error("Error saving user:", error);
      toast({
        title: "Error",
        description: "Could not save user details. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const cardBg = useColorModeValue("white", "gray.800");
  const sectionBg = useColorModeValue("gray.50", "gray.700");

  return (
    <Box mx="auto" p={6} maxW="container.sm">
      <Card bg={cardBg} shadow="md" borderRadius="lg">
        <CardHeader pb={0} textAlign="center">
          <Heading as="h2" size="lg">
            {action === "create"
              ? "Create Organization User"
              : "Edit Organization User"}
          </Heading>
        </CardHeader>

        <CardBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={5} align="stretch">
              {/* First Name */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    placeholder="Enter user's first name"
                  />
                </FormControl>
              </Box>

              {/* Last Name */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    placeholder="Enter user's last name"
                  />
                </FormControl>
              </Box>

              {/* Email */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter user's email address"
                  />
                </FormControl>
              </Box>

              <Flex>
                <Button variant="outline" onClick={handleCancel} size="sm">
                  Cancel
                </Button>

                <Spacer />
                
                <Button
                  colorScheme="green"
                  size="sm"
                  variant="outline"
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Saving"
                >
                  {action === "create" ? "Create User" : "Save Changes"}
                </Button>
              </Flex>
            </VStack>
          </form>
        </CardBody>
      </Card>
    </Box>
  );
};

export default OrganizationUserForm;