import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Cookies from 'js-cookie';
import { useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const colorMode = useColorMode();
  
  // 🔹 Load initial settings from local storage to prevent flash of wrong language
  const initialSettings = {
    theme: localStorage.getItem('theme') || 'light',
    language: localStorage.getItem('language') || 'english',
  };
  
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState(initialSettings);
  const [currentLanguage, setCurrentLanguage] = useState(initialSettings.language);

  // 🔹 Ensure i18n applies the correct language on app startup
  useEffect(() => {
    i18n.changeLanguage(settings.language);
    setCurrentLanguage(settings.language); // Ensure components re-render
    if (colorMode.setColorMode) {
      colorMode.setColorMode(settings.theme);
    }
  }, []); // Only runs once on mount
  
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = Cookies.get('hkeAuthToken');
        if (!token) {
          setUser(null);
          setIsLoading(false);
          return;
        }

        const response = await axios.get('/users/me', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUser(response.data);

        if (response.data.settings) {
          setSettings(response.data.settings);

          // 🔹 Apply language settings immediately after fetch
          if (response.data.settings.language) {
            i18n.changeLanguage(response.data.settings.language);
            localStorage.setItem('language', response.data.settings.language);
            setCurrentLanguage(response.data.settings.language);
          }

          // 🔹 Apply theme settings
          if (colorMode.setColorMode && response.data.settings.theme) {
            colorMode.setColorMode(response.data.settings.theme);
            localStorage.setItem('theme', response.data.settings.theme);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (colorMode.setColorMode && settings.theme) {
      colorMode.setColorMode(settings.theme);
    }

    if (settings.language) {
      i18n.changeLanguage(settings.language);
    }
  }, [settings, colorMode, i18n]);

  // 🔹 Function to change password
  const changePassword = async (currentPassword, newPassword) => {
    try {
      const response = await axios.patch('/users/me/change_password', {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPassword,
      });

      return response.status === 200;
    } catch (error) {
      console.error('Error changing password:', error);
      return false;
    }
  };

  // 🔹 Function to update user profile
  const updateUser = async (updatedData) => {
    try {
      const response = await axios.patch('/users/me/profile', updatedData);
      if (response.status === 200) {
        setUser(response.data.user); // Update the user state
        return true;
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
      return false;
    }
  };

  // 🔹 Function to update user settings (theme, language, etc.)
  const updateSettings = async (newSettings) => {
    try {
      const response = await axios.patch('/users/me/settings', newSettings);

      if (response.status === 200) {
        const updatedSettings = { ...settings, ...newSettings };
        setSettings(updatedSettings);

        // 🔹 Persist settings in local storage
        if (newSettings.language) {
          i18n.changeLanguage(newSettings.language);
          localStorage.setItem('language', newSettings.language);
          setCurrentLanguage(newSettings.language);
        }

        if (newSettings.theme) {
          colorMode.setColorMode(newSettings.theme);
          localStorage.setItem('theme', newSettings.theme);
        }

        return true;
      }
    } catch (error) {
      console.error('Error updating user settings:', error);
      return false;
    }
  };

  return (
    <UserContext.Provider value={{
      user,
      setUser,
      isLoading,
      settings,
      setSettings,
      changePassword,
      updateUser,
      currentLanguage,
      updateSettings
    }}>
      {children}
    </UserContext.Provider>
  );
};