import React from "react";
import { Routes, Route } from "react-router-dom";
import ReportLayout from "./ReportLayout";
// import ReportCover from "./ReportCover";
import Strengths from "./Strengths";
import Development from "./Development";
import Input from "./Input";
import Processes from "./Processes";
import ClimateCulture from "./ClimateCulture";
import PlcGroup from "./PlcGroup";
import Recommendations from "./Recommendations";
import StabilityOverview from "./StabilityOverview";

const ReportRoutes = ({ isHub }) => {
  return (
    <Routes>
      {/* Wrap report pages inside ReportLayout */}
      <Route path=":organizationSurveyId/*" element={<ReportLayout isHub={isHub} />}>
        <Route index element={<StabilityOverview isHub={isHub} />} /> 
        {/* <Route path="report" element={<ReportCover isHub={isHub} />} /> */}
        <Route path="report" element={<StabilityOverview isHub={isHub} />} />
        <Route path="strengths" element={<Strengths />} />
        <Route path="development" element={<Development />} />
        <Route path="input" element={<Input />} />
        <Route path="processes" element={<Processes />} />
        <Route path="climate-culture" element={<ClimateCulture />} />
        <Route path="plc-group" element={<PlcGroup />} />
        <Route path="recommendations" element={<Recommendations />} />
      </Route>
    </Routes>
  );
};

export default ReportRoutes;