import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Spinner,
  Alert,
  AlertIcon,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  SimpleGrid,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useUser } from '../../../contexts/UserContext';
import axios from '../../../axiosConfig';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OrgSurveys = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [surveys, setSurveys] = useState([]); // ✅ Changed to an array
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        const response = await axios.get('/organizations/me/surveys');
        setSurveys(response.data); // ✅ Now expecting an array
      } catch (error) {
        toast({
          title: t("hub.org_surveys.error"),
          description: t("hub.org_surveys.error"),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchSurveys();
  }, [toast, t]);

  return (
    <Box p={6} maxW="container.md" mx="auto">
      <Heading as="h2" size="lg" mb={4} textAlign="center">
        {t("hub.org_surveys.title")}
      </Heading>

      {loading ? (
        <Spinner size="xl" mt={6} display="block" mx="auto" />
      ) : surveys.length > 0 ? ( // ✅ Checks if we have surveys
        <VStack spacing={6} align="stretch">
          {surveys.map((survey) => ( // ✅ Iterate over surveys
            <Box key={survey.organization_survey_id} p={4} shadow="md" borderWidth="1px" borderRadius="lg" backgroundColor="white">
              <Heading as="h3" size="md" mb={2}>{survey.name}</Heading>
              <Text fontSize="sm" color="gray.600">
                {t("hub.org_surveys.language")}: {survey.language?.toUpperCase()} | 
                {t("hub.org_surveys.pages")}: {survey.pages_count} | 
                {t("hub.org_surveys.questions")}: {survey.questions_count}
              </Text>

              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
                <Stat p={4} shadow="md" borderWidth="1px" borderRadius="lg">
                  <StatLabel>{t("hub.org_surveys.total_respondents")}</StatLabel>
                  <StatNumber>{survey.total_respondents}</StatNumber>
                  <StatHelpText>{survey.completion_rate}% {t("hub.org_surveys.completion_rate")}</StatHelpText>
                </Stat>

                <Stat p={4} shadow="md" borderWidth="1px" borderRadius="lg">
                  <StatLabel>{t("hub.org_surveys.completed_surveys")}</StatLabel>
                  <StatNumber>{survey.completed_surveys}</StatNumber>
                </Stat>
              </SimpleGrid>

              <Button
                colorScheme="blue"
                size="md"
                width="full"
                mt={4}
                onClick={() => navigate(`/hub/surveys/configuration/${survey.organization_survey_id}`)}
                variant="outline"
              >
                {t("hub.org_surveys.edit_survey")}
              </Button>
            </Box>
          ))}
        </VStack>
      ) : (
        <Alert status="info">
          <AlertIcon />
          {t("hub.org_surveys.no_surveys")}
        </Alert>
      )}
    </Box>
  );
};

export default OrgSurveys;