import React, { useState, useEffect } from 'react';
import { 
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Text,
  Avatar,
  Card,
  CardHeader,
  CardBody,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import { useUser } from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';

const Profile = ({ redirectTo }) => {
  const { t } = useTranslation();
  const { user, updateUser } = useUser();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const toast = useToast();

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = await updateUser({ first_name: firstName, last_name: lastName, email });

    if (success) {
      toast({
        title: t("profile.profile_updated"),
        description: t("profile.profile_saved"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      if (redirectTo) {
        window.location.href = redirectTo;
      }
    } else {
      toast({
        title: t("profile.profile_error"),
        description: t("profile.profile_error_desc"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const cardBg = useColorModeValue("white", "gray.800");
  const sectionBg = useColorModeValue("gray.50", "gray.700");

  return (
    <Box mx="auto" p={6} maxW="container.md">
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        {t("profile.title")}
      </Heading>

      <Card bg={cardBg} shadow="md" borderRadius="lg">
        <CardHeader pb={0} textAlign="center">
          <Avatar name={`${firstName} ${lastName}`} size="xl" mb={3} />
          <Text fontSize="lg" fontWeight="bold">
            {firstName} {lastName}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {email}
          </Text>
        </CardHeader>

        <CardBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={5} align="stretch">
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>{t("profile.first_name")}</FormLabel>
                  <Input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FormControl>
              </Box>

              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>{t("profile.last_name")}</FormLabel>
                  <Input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FormControl>
              </Box>

              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>{t("profile.email")}</FormLabel>
                  <Input type="email" value={email} disabled />
                </FormControl>
              </Box>

              <Button
                type="submit"
                colorScheme="green"
                width="full"
                size="lg"
                variant="outline"
              >
                {t("profile.save_changes")}
              </Button>
            </VStack>
          </form>
        </CardBody>
      </Card>
    </Box>
  );
};

export default Profile;