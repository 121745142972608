import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useToast,
  useColorModeValue,
  Card,
  CardBody,
  Divider,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import axios from "../../../axiosConfig";

const OrgDashboard = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [stats, setStats] = useState({
    totalSurveys: 0,
    totalRespondents: 0,
    completedSurveys: 0,
    completionRate: 0,
    responseTrends: [],
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get("/organizations/me/stats"); // API call for Hub stats
        setStats(response.data);
      } catch (error) {
        console.error("Error fetching stats:", error);
        toast({
          title: "Error",
          description: "Could not load organization stats.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchStats();
  }, [toast]);

  // Define colors for the stats cards
  const statColors = {
    totalSurveys: "blue.50",
    totalRespondents: "green.50",
    completedSurveys: "purple.50",
    completionRate: "yellow.50",
  };

  // Prepare chart data
  const chartData = {
    labels: stats.responseTrends.map((trend) => trend.date),
    datasets: [
      {
        label: t("hub.dashboard.surveyResponses"),
        data: stats.responseTrends.map((trend) => trend.responses),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
        hoverBackgroundColor: "rgba(54, 162, 235, 0.8)",
      },
    ],
  };

  return (
    <Box p={6}>
      {/* Welcome Message */}
      <Heading as="h1" size="xl">
        {t("hub.dashboard.welcome")}
      </Heading>
      <Text fontSize="lg" mt={2}>
        {t("hub.dashboard.overview")}
      </Text>

      {/* Stats Section */}
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6} mt={6}>
        <Card bg={statColors.totalSurveys} p={4} shadow="md" borderRadius="md">
          <CardBody>
            <Stat>
              <StatLabel>{t("hub.dashboard.totalSurveys")}</StatLabel>
              <StatNumber fontSize="4xl">{stats.totalSurveys}</StatNumber>
            </Stat>
          </CardBody>
        </Card>

        <Card bg={statColors.totalRespondents} p={4} shadow="md" borderRadius="md">
          <CardBody>
            <Stat>
              <StatLabel>{t("hub.dashboard.totalRespondents")}</StatLabel>
              <StatNumber fontSize="4xl">{stats.totalRespondents}</StatNumber>
            </Stat>
          </CardBody>
        </Card>

        <Card bg={statColors.completedSurveys} p={4} shadow="md" borderRadius="md">
          <CardBody>
            <Stat>
              <StatLabel>{t("hub.dashboard.completedSurveys")}</StatLabel>
              <StatNumber fontSize="4xl">{stats.completedSurveys}</StatNumber>
            </Stat>
          </CardBody>
        </Card>

        <Card bg={statColors.completionRate} p={4} shadow="md" borderRadius="md">
          <CardBody>
            <Stat>
              <StatLabel>{t("hub.dashboard.completionRate")}</StatLabel>
              <StatNumber fontSize="4xl">{stats.completionRate}%</StatNumber>
            </Stat>
          </CardBody>
        </Card>
      </SimpleGrid>

      <Divider my={8} />

      {/* Survey Trends Graph */}
      <Box
        mt={6}
        p={4}
        shadow="md"
        borderRadius="md"
        bg={useColorModeValue("white", "gray.800")}
      >
        <Heading as="h3" size="md" mb={4}>
          {t("hub.dashboard.surveyTrends")}
        </Heading>
        {stats.responseTrends.length > 0 ? (
          <Box maxHeight="300px">
            <Bar data={chartData} />
          </Box>
        ) : (
          <Text>{t("hub.dashboard.noData")}</Text>
        )}
      </Box>
    </Box>
  );
};

export default OrgDashboard;