import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Heading,
  Spinner,
  VStack,
  Select,
  useToast,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';

const ResultsPage = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [results, setResults] = useState([]); // Store surveys
  const [selectedSurveyId, setSelectedSurveyId] = useState(null); // Selected survey for export
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Export settings
  const [csvLoading, setCsvLoading] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState('csv_semi'); // CSV Format
  const [sortBy, setSortBy] = useState('none'); // Sorting (role, group, none)
  const [surveyCompleted, setSurveyCompleted] = useState('all'); // Completed, In-progress, All

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/organizations/${organizationId}/results`);
        setResults(response.data); // Store available surveys
      } catch (error) {
        console.error('Failed to fetch results:', error);
        setError('Failed to load survey results. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [organizationId]);

  // Export CSV Handler
  const downloadCSV = async () => {
    if (!selectedSurveyId) return;
    try {
      setCsvLoading(true);
      const params = { organization_survey_id: selectedSurveyId, format: selectedFormat, sort_by: sortBy, completed: surveyCompleted };
      const response = await axios.get(`/organizations/${organizationId}/export_results`, { params, responseType: 'blob' });

      const timestamp = new Date().toISOString().replace(/[-:T]/g, '').slice(0, 14);
      const filename = `survey_results_${selectedSurveyId}_${timestamp}.csv`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Failed to download CSV:', error);
    } finally {
      setCsvLoading(false);
    }
  };

  if (error) return <Box color="red.500">{error}</Box>;

  return (
    <Box p={6}>
      <Heading mb={6}>Survey Results</Heading>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {/* Survey Report Buttons */}
          <VStack spacing={4} align="start">
            {results.map((survey) => (
              <Button
                key={survey.surveyId}
                colorScheme="purple"
                variant="outline"
                onClick={() => navigate(`/app/results/${survey.surveyId}`)}
              >
                {survey.surveyName} ({survey.period})
              </Button>
            ))}
          </VStack>

          <Divider my={6} />

          {/* Export CSV Section */}
          <Heading size="md" mb={4}>Export CSV</Heading>
          <Select placeholder="Select a Survey for Export" onChange={(e) => setSelectedSurveyId(e.target.value)} mb={4}>
            {results.map((survey) => (
              <option key={survey.surveyId} value={survey.surveyId}>
                {survey.surveyName}
              </option>
            ))}
          </Select>

          <Select value={selectedFormat} onChange={(e) => setSelectedFormat(e.target.value)} mb={2}>
            <option value="csv_comma">CSV (Comma-Separated)</option>
            <option value="csv_semi">CSV (Semicolon-Separated)</option>
          </Select>

          <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)} mb={2}>
            <option value="none">No Sorting</option>
            <option value="group">Sort by Profession</option>
            <option value="role">Sort by Team</option>
          </Select>
          
          <Select value={surveyCompleted} onChange={(e) => setSurveyCompleted(e.target.value)} mb={4}>
            <option value="all">Include All Surveys</option>
            <option value="completed">Only Completed</option>
            <option value="in_progress">Only In-Progress</option>
          </Select>

          <Button colorScheme="green" onClick={downloadCSV} isLoading={csvLoading} loadingText="Generating...">
            Download CSV
          </Button>
        </>
      )}
    </Box>
  );
};

export default ResultsPage;
