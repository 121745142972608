import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Button,
  useToast,
  VStack,
  Heading,
  Text,
  InputGroup,
  InputRightElement,
  Card,
  CardHeader,
  CardBody,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {
  const { t } = useTranslation();
  const { changePassword } = useUser();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  // Detect if user is in Hub or App
  const isHubUser = location.pathname.startsWith('/hub');

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast({
        title: t('change_password.passwords_do_not_match'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const success = await changePassword(currentPassword, newPassword);
      if (success) {
        toast({
          title: t('change_password.password_changed_success'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Redirect to profile based on area
        navigate(`/${isHubUser ? 'hub' : 'app'}/profile`);
      }
    } catch (error) {
      console.log('Failing to change password with error..: ', error);
      toast({
        title: t('change_password.password_change_failed'),
        description: error.response?.data?.message || t('change_password.password_change_try_again'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const cardBg = useColorModeValue('white', 'gray.800');
  const sectionBg = useColorModeValue('gray.50', 'gray.700');

  return (
    <Box mx="auto" p={6} maxW="container.md">
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        {isHubUser ? t('change_password.security_settings') : t('change_password.title')}
      </Heading>

      <Card bg={cardBg} shadow="md" borderRadius="lg">
        <CardHeader pb={0}>
          <Text fontSize="lg" fontWeight="bold">
            {isHubUser ? t('change_password.manage_security') : t('change_password.update_your_password')}
          </Text>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleChangePassword}>
            <VStack spacing={6} align="stretch">
              {/* Current Password Section */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>{t('change_password.current_password')}</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      placeholder={t('change_password.enter_current_password')}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                        {showPassword ? t('change_password.hide') : t('change_password.show')}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Box>

              <Divider />

              {/* New Password Section */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>{t('change_password.new_password')}</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder={t('change_password.enter_new_password')}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl mt={4} isRequired>
                  <FormLabel>{t('change_password.confirm_new_password')}</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder={t('change_password.reenter_new_password')}
                    />
                  </InputGroup>
                </FormControl>
              </Box>

              {/* Buttons */}
              <Flex width="100%" justifyContent="space-between" mt={4}>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  onClick={() => navigate(isHubUser ? '/hub/profile' : '/app/profile')}
                >
                  {t('change_password.cancel')}
                </Button>
                <Button type="submit" colorScheme="green" variant="outline">
                  {t('change_password.update_password')}
                </Button>
              </Flex>
            </VStack>
          </form>
        </CardBody>
      </Card>
    </Box>
  );
};

export default ChangePassword;