import { Box, Divider, Heading, Flex, Text } from "@chakra-ui/react";
import { RecommendationsIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

const Recommendations = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Flex align="center" mb={4}>
        <RecommendationsIcon />
        <Heading as="h1" size="2xl" color="#333333" ml={3}>
          {t("reports.recommendations.title")}
        </Heading>
      </Flex>

      <Text fontSize="xl" color="gray.500" mb={4}>
        {t("reports.recommendations.description")}
      </Text>
      <Divider />
    </Box>
  );
};

export default Recommendations;