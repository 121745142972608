import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from '../axiosConfig';

const FeatureToggleContext = createContext();

export const useFeatureToggle = () => useContext(FeatureToggleContext);

export const FeatureToggleProvider = ({ children }) => {
  const [toggles, setToggles] = useState({});

  useEffect(() => {
    const fetchFeatureToggles = async () => {
      try {
        const response = await axios.get('/feature_toggles');
        const toggleData = response.data.reduce((acc, feature) => {
          acc[feature.key] = {
            active: feature.active,
            value: feature.value,
          };
          return acc;
        }, {});
        setToggles(toggleData);
      } catch (error) {
        console.error('Error fetching feature toggles:', error);
      }
    };

    fetchFeatureToggles();
  }, []);

  // 🔹 Check if a feature is enabled
  const isFeatureEnabled = (key) => toggles[key]?.active || false;

  // 🔹 Get the value of a feature (or a default value)
  const getFeatureValue = (key, defaultValue = null) => toggles[key]?.value ?? defaultValue;

  return (
    <FeatureToggleContext.Provider value={{ isFeatureEnabled, getFeatureValue }}>
      {children}
    </FeatureToggleContext.Provider>
  );
};