import React from 'react';
import {
  Flex,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  IconButton,
  useDisclosure,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  HStack,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  Icon,
  Link as ChakraLink,
  useColorModeValue
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  FaUser,
  FaUserCog,
  FaUserTie,
  FaTachometerAlt,
  FaKey,
  FaUsers,
  FaEnvelope,
  FaClipboardList,
  FaSignOutAlt,
  FaWrench
} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';  
import Cookies from 'js-cookie';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils';

// Function to create dynamic breadcrumb items based on the current path
const createBreadcrumbs = (location) => {
  const pathnames = location.pathname
    .split('/')
    .filter((x) => x && !['app', 'dashboard', 'edit'].includes(x)); // Filter out the 'app' and 'home'

  const breadcrumbs = pathnames.map((value, index) => {
    const to = `/app/${pathnames.slice(0, index + 1).join('/')}`;
    return { label: value.charAt(0).toUpperCase() + value.slice(1), href: to };
  });

  return breadcrumbs;
};

const Navbar = () => {
  const { t } = useTranslation();  // Initialize the translation hook
  const location = useLocation();  // Get current location
  const breadcrumbs = createBreadcrumbs(location);  // Generate dynamic breadcrumbs
  const { user } = useUser();  // Access user data from context
  const { isOpen, onOpen, onClose } = useDisclosure();
  const colorMode = useColorModeValue('light', 'dark');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.700');
  const logo = colorMode === 'dark' ? 'logo-white-md.png' : 'logo-md.png';

  // Get background and text color dynamically based on theme
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');

  const handleLogout = () => {
    Cookies.remove('hkeAuthToken');
    window.location.href = '/login';
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      padding="1rem"
      backgroundColor={bgColor}
      color={textColor}
      position="sticky"
      top="0"
      zIndex="90"
    >
      {/* Company logo on the left */}
      <Box display={{ base: 'block', md: 'none' }}>
        <img src={`/images/${logo}`} alt="Company Logo" style={{ width: '100px' }} />
      </Box>

      {/* Breadcrumbs */}
      <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />} display={{ base: 'none', md: 'flex' }}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/app/dashboard">
            {t('navbar.dashboard')}
          </BreadcrumbLink>
        </BreadcrumbItem>
        {breadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbItem key={index}>
            <BreadcrumbLink as={Link} to={breadcrumb.href}>
              {breadcrumb.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>

      {/* Burger button for mobile on the right */}
      <Box display={{ base: 'block', md: 'none' }}>
        <IconButton
          icon={<HamburgerIcon />}
          aria-label={t('navbar.menu')}
          onClick={onOpen}
          variant="outline"
        />
      </Box>



      {/* User info and actions */}
      <HStack spacing={4} display={{ base: 'none', md: 'flex' }}>
        {user && (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={
                <Box as={FaUser} w={4} h={4} borderRadius="full" />
              }
              size="sm"
              variant="ghost"
              borderRadius="full"
              backgroundColor={bgColor}
              _hover={{ backgroundColor: hoverBgColor }}
            />
            <MenuList>
              <MenuItem>{`${user.first_name} ${user.last_name}`}</MenuItem>
              <Text as="i" ml={3} fontSize="sm" color="gray">Role: {capitalize(user.role)}</Text>
              <MenuDivider />
              <MenuItem as={Link} to="/app/profile">{t('navbar.profile')}</MenuItem>
              <MenuItem as={Link} to="/app/settings">{t('navbar.preferences')}</MenuItem>
              <MenuItem as={Link} to="/app/password">{t('navbar.password')}</MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleLogout}>{t('navbar.logout')}</MenuItem>
            </MenuList>
          </Menu>
        )}
      </HStack>

      {/* Drawer for mobile menu */}
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t('navbar.menu')}</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              <ChakraLink as={Link} to="/app/dashboard" onClick={onClose}>
                <Icon as={FaTachometerAlt} mr={2} />
                {t('navbar.dashboard')}
              </ChakraLink>
              <ChakraLink as={Link} to="/app/organizations" onClick={onClose}>
                <Icon as={FaUserTie} mr={2} />
                {t('navbar.organization')}
              </ChakraLink>

              <ChakraLink as={Link} to="/app/surveys" onClick={onClose}>
                <Icon as={FaClipboardList} mr={2} />
                {t('navbar.surveys')}
              </ChakraLink>
              <ChakraLink as={Link} to="/app/messages" onClick={onClose}>
                <Icon as={FaEnvelope} mr={2} />
                {t('navbar.messages')}
              </ChakraLink>
              <ChakraLink as={Link} to="/app/users" onClick={onClose}>
                <Icon as={FaUsers} mr={2} />
                {t('navbar.users')}
              </ChakraLink>
              {user?.role === 'tech' && (
                <ChakraLink as={Link} to="/app/devtools" onClick={onClose}>
                  <Icon as={FaWrench} mr={2} />
                  {t('navbar.devtools')}
                </ChakraLink>
              )}

              <Divider />

              {/* Profile and Settings Links */}
              <ChakraLink as={Link} to="/app/profile" onClick={onClose}>
                <Icon as={FaUser} mr={2} />
                {t('navbar.profile')}
              </ChakraLink>
              <ChakraLink as={Link} to="/app/settings" onClick={onClose}>
                <Icon as={FaUserCog} mr={2} />
                {t('navbar.settings')}
              </ChakraLink>
              <ChakraLink as={Link} to="/app/password" onClick={onClose}>
                <Icon as={FaKey} mr={2} />
                {t('navbar.password')}
              </ChakraLink>

              <Divider />

              {/* Logout */}
              <ChakraLink as="button" onClick={handleLogout}>
                <Icon as={FaSignOutAlt} mr={2} />
                {t('navbar.logout')}
              </ChakraLink>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Navbar;