import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  VStack,
  Select,
  Spacer,
  Card,
  CardHeader,
  CardBody,
  useColorModeValue,
  Textarea
} from "@chakra-ui/react";
import { getAreaName } from "../../utils/areaNames";

const SurveyPageForm = ({ initialData, onSubmit, onCancel }) => {
  const [name, setName] = useState(initialData?.name || "");
  const [position, setPosition] = useState(initialData?.position || "");
  const [area, setArea] = useState(initialData?.area || "");
  const [factor, setFactor] = useState(initialData?.factor || "");
  const [strength, setStrength] = useState(initialData?.strength || "");
  const [development, setDevelopment] = useState(initialData?.development || "");

  useEffect(() => {
    setName(initialData?.name || "");
    setPosition(initialData?.position || "");
    setArea(initialData?.area || "");
    setFactor(initialData?.factor || "");
    setStrength(initialData?.strength || "");
    setDevelopment(initialData?.development || "");
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name, position, area, factor, strength, development });
  };

  const areaOptions = [
    { value: "input", label: getAreaName("input") },
    { value: "processes", label: getAreaName("processes") },
    { value: "climate_and_culture", label: getAreaName("climate_and_culture") },
  ];

  const cardBg = useColorModeValue("white", "gray.800");
  const sectionBg = useColorModeValue("gray.50", "gray.700");

  return (
    <Box mx="auto" p={6} maxW="container.md">
      <Card bg={cardBg} shadow="md" borderRadius="lg">
        <CardHeader pb={0} textAlign="center">
          <FormLabel fontSize="xl" fontWeight="bold">
            {initialData ? "Edit Page" : "Create Page"}
          </FormLabel>
        </CardHeader>

        <CardBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={5} align="stretch">
              {/* Page Name */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>Page Name</FormLabel>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter page name"
                  />
                </FormControl>
              </Box>

              {/* Position */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl>
                  <FormLabel>Position</FormLabel>
                  <NumberInput min={1} value={position} onChange={(value) => setPosition(value)}>
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              </Box>

              {/* Area Selection */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl isRequired>
                  <FormLabel>Area</FormLabel>
                  <Select value={area} onChange={(e) => setArea(e.target.value)} placeholder="Select an area">
                    {areaOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Factor */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl>
                  <FormLabel>Factor</FormLabel>
                  <Input
                    value={factor}
                    onChange={(e) => setFactor(e.target.value)}
                    placeholder="Enter factor"
                  />
                </FormControl>
              </Box>

              {/* Strength */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl>
                  <FormLabel>Strength</FormLabel>
                  <Textarea
                    value={strength}
                    onChange={(e) => setStrength(e.target.value)}
                    placeholder="Enter strength description"
                    size="md"
                  />
                </FormControl>
              </Box>

              {/* Development */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <FormControl>
                  <FormLabel>Development</FormLabel>
                  <Textarea
                    value={development}
                    onChange={(e) => setDevelopment(e.target.value)}
                    placeholder="Enter development description"
                    size="md"
                  />
                </FormControl>
              </Box>

              {/* Buttons */}
              <Flex>
                <Button variant="outline" onClick={onCancel} size="sm">
                  Cancel
                </Button>

                <Spacer />
                
                <Button type="submit" colorScheme="green" size="sm" variant="outline">
                  {initialData ? "Update Page" : "Create Page"}
                </Button>
              </Flex>
            </VStack>
          </form>
        </CardBody>
      </Card>
    </Box>
  );
};

export default SurveyPageForm;