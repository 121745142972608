import React, { useState, useEffect } from 'react';
import { 
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  useToast,
  Card,
  CardHeader,
  CardBody,
  Divider,
  useColorModeValue,
  Text,
  Select,
  Textarea
} from '@chakra-ui/react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from '../../axiosConfig';
import { useTranslation } from 'react-i18next';

const OrganizationForm = ({ id: propId }) => {
  const { t } = useTranslation();
  const { id: paramId } = useParams();
  const location = useLocation();
  const id = propId || paramId;
  const [organization, setOrganization] = useState({
    name: '',
    contact: '',
    title: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    notes: '',
    // Billing Fields
    billing_same_as_org: true,
    billing_company_name: '',
    billing_address: '',
    billing_zip: '',
    billing_city: '',
    billing_state: '',
    billing_country: '',
    vat_or_tax_number: '',
    currency: '',
    billing_method: '',
    billing_email: '',
    billing_method_other: '',
    reference_person: '',
    reference_number: '',
    cost_center: '',
    billing_notes: ''
  });
  const navigate = useNavigate();
  const toast = useToast();
  const isHub = location.pathname.startsWith("/hub");

  useEffect(() => {
    if (id) {
      const fetchOrganization = async () => {
        try {
          const response = await axios.get(`/organizations/${id}`);
          setOrganization(response.data);
        } catch (error) {
          console.error('Error fetching organization:', error);
        }
      };
      fetchOrganization();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganization((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await axios.put(`/organizations/${id}`, organization);
        toast({ title: t("organization.organization_updated"), status: "success" });
      } else {
        await axios.post('/organizations', organization);
        toast({ title: t("organization.organization_created"), status: "success" });
      }
      navigate(isHub ? "/hub/settings" : "/app/organizations");
    } catch (error) {
      console.error('Error saving organization:', error);
      toast({ title: t("organization.organization_save_error"), status: "error" });
    }
  };

  const cardBg = useColorModeValue("white", "gray.800");
  const sectionBg = useColorModeValue("gray.50", "gray.700");

  // Favorite and all countries list
  const favoriteCountries = ["Australia", "Germany", "Sweden", "United Kingdom"];
  const allCountries = [
    "Australia", "Canada", "Denmark", "France", "Germany", "India", "Japan",
    "Netherlands", "Norway", "Spain", "Sweden", "Switzerland", "United Kingdom", "United States"
  ];
  

  return (
    <Box mx="auto" p={6} maxW="container.md">
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        {isHub ? t("organization.settings") : id ? t("organization.edit_organization") : t("organization.create_organization")}
      </Heading>

      <Card bg={cardBg} shadow="md" borderRadius="lg">
        <CardHeader pb={0}>
          <Text fontSize="lg" fontWeight="bold">
            {isHub ? t("organization.update_details") : t("organization.manage_organization")}
          </Text>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={6} align="stretch">
              {/* Basic Info */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <Heading as="h4" size="sm" mb={2}>
                  {t("organization.general_info")}
                </Heading>
                <FormControl isRequired>
                  <FormLabel>{t("organization.organization_name")}</FormLabel>
                  <Input name="name" value={organization.name} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.contact_person")}</FormLabel>
                  <Input name="contact" value={organization.contact} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.title")}</FormLabel>
                  <Input name="title" value={organization.title} onChange={handleChange} />
                </FormControl>
              </Box>

              <Divider />

              {/* Contact Info */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <Heading as="h4" size="sm" mb={2}>
                  {t("organization.contact_info")}
                </Heading>
                <FormControl isRequired>
                  <FormLabel>{t("organization.email")}</FormLabel>
                  <Input name="email" value={organization.email} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.phone")}</FormLabel>
                  <Input name="phone" value={organization.phone} onChange={handleChange} />
                </FormControl>
              </Box>

              {/* Address Info */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <Heading as="h4" size="sm" mb={2}>
                  {t("organization.location")}
                </Heading>
                <FormControl mt={3}>
                  <FormLabel>{t("organization.address")}</FormLabel>
                  <Input name="address" value={organization.address} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.city")}</FormLabel>
                  <Input name="city" value={organization.city} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.state")}</FormLabel>
                  <Input name="state" value={organization.state} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3} isRequired>
                  <FormLabel>{t("organization.country")}</FormLabel>
                  <Select name="country" value={organization.country} onChange={handleChange}>
                    {favoriteCountries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                    <option disabled>──────────</option> {/* Separator */}
                    {allCountries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.zip")}</FormLabel>
                  <Input name="zip" value={organization.zip} onChange={handleChange} />
                </FormControl>
              </Box>

              {/* Notes */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <Heading as="h4" size="sm" mb={2}>
                  {t("organization.notes")}
                </Heading>
                <FormControl>
                  <FormLabel>{t("organization.notes")}</FormLabel>
                  <Textarea
                    name="notes"
                    value={organization.notes}
                    onChange={handleChange}
                    placeholder={t("organization.notes_placeholder")}
                  />
                </FormControl>
              </Box>

              {/* Billing Info */}
              <Box bg={sectionBg} p={4} borderRadius="md">
                <Heading as="h4" size="sm" mb={2}>
                  {t("organization.billing_info")}
                </Heading>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.billing_same_as_org")}</FormLabel>
                  <Select name="billing_same_as_org" value={organization.billing_same_as_org ? "true" : "false"} onChange={(e) => {
                    const value = e.target.value === "true";
                    setOrganization((prev) => ({ ...prev, billing_same_as_org: value }));
                  }}>
                    <option value="true">{t("common.yes")}</option>
                    <option value="false">{t("common.no")}</option>
                  </Select>
                </FormControl>

                {!organization.billing_same_as_org && (
                  <>
                    <FormControl mt={3}>
                      <FormLabel>{t("organization.billing_address")}</FormLabel>
                      <Input name="billing_address" value={organization.billing_address} onChange={handleChange} />
                    </FormControl>

                    <FormControl mt={3}>
                      <FormLabel>{t("organization.billing_city")}</FormLabel>
                      <Input name="billing_city" value={organization.billing_city} onChange={handleChange} />
                    </FormControl>

                    <FormControl mt={3}>
                      <FormLabel>{t("organization.billing_state")}</FormLabel>
                      <Input name="billing_state" value={organization.billing_state} onChange={handleChange} />
                    </FormControl>

                    <FormControl mt={3}>
                      <FormLabel>{t("organization.billing_zip")}</FormLabel>
                      <Input name="billing_zip" value={organization.billing_zip} onChange={handleChange} />
                    </FormControl>

                    <FormControl mt={3}>
                      <FormLabel>{t("organization.billing_country")}</FormLabel>
                      <Select name="billing_country" value={organization.billing_country} onChange={handleChange}>
                        {favoriteCountries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                        <option disabled>──────────</option>
                        {allCountries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}

                <FormControl mt={3}>
                  <FormLabel>{t("organization.billing_company_name")}</FormLabel>
                  <Input name="billing_company_name" value={organization.billing_company_name} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.vat_or_tax_number")}</FormLabel>
                  <Input name="vat_or_tax_number" value={organization.vat_or_tax_number} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.currency")}</FormLabel>
                  <Input name="currency" value={organization.currency} onChange={handleChange} placeholder="e.g. USD, EUR, SEK" />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.billing_method")}</FormLabel>
                  <Select name="billing_method" value={organization.billing_method} onChange={handleChange}>
                    <option value="">{t("common.select")}</option>
                    <option value="invoice_email">{t("organization.billing_method_invoice")}</option>
                    <option value="direct_debit">{t("organization.billing_method_debit")}</option>
                    <option value="credit_card">{t("organization.billing_method_card")}</option>
                    <option value="other">{t("common.other")}</option>
                  </Select>
                </FormControl>

                {organization.billing_method === "invoice_email" && (
                  <FormControl mt={3}>
                    <FormLabel>{t("organization.billing_email")}</FormLabel>
                    <Input name="billing_email" value={organization.billing_email} onChange={handleChange} />
                  </FormControl>
                )}

                {organization.billing_method === "other" && (
                  <FormControl mt={3}>
                    <FormLabel>{t("organization.billing_method_other")}</FormLabel>
                    <Input name="billing_method_other" value={organization.billing_method_other} onChange={handleChange} />
                  </FormControl>
                )}

                <FormControl mt={3}>
                  <FormLabel>{t("organization.reference_person")}</FormLabel>
                  <Input name="reference_person" value={organization.reference_person} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.reference_number")}</FormLabel>
                  <Input name="reference_number" value={organization.reference_number} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.cost_center")}</FormLabel>
                  <Input name="cost_center" value={organization.cost_center} onChange={handleChange} />
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>{t("organization.billing_notes")}</FormLabel>
                  <Textarea
                    name="billing_notes"
                    value={organization.billing_notes}
                    onChange={handleChange}
                    placeholder={t("organization.billing_notes_placeholder")}
                  />
                </FormControl>
              </Box>

              <Button type="submit" colorScheme="green" variant="outline">
                {id ? t("organization.update") : t("organization.create")}
              </Button>
            </VStack>
          </form>
        </CardBody>
      </Card>
    </Box>
  );
};

export default OrganizationForm;