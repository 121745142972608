import React, { useState, useEffect } from "react";
import { Box, Heading, Text, VStack, Button, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "../../../axiosConfig";
import { useTranslation } from "react-i18next";

const OrgResults = ({ orgId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch the surveys linked to this organization
    axios.get(`/organizations/me/surveys`)
      .then((response) => {
        setSurveys(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(t("hub.org_results.error"));
        setLoading(false);
      });
  }, [orgId, t]);

  if (loading) {
    return <Spinner size="xl" label={t("hub.org_results.loading")} />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <Box p={6} maxW="container.md" mx="auto" textAlign="center">
      <VStack spacing={5}>
        <Heading as="h2" size="lg">
          {t("hub.org_results.title")}
        </Heading>
        <Text fontSize="md" color="gray.600">
          {t("hub.org_results.description")}
        </Text>

        {surveys.length === 0 ? (
          <Text fontSize="md" color="gray.500">{t("hub.org_results.no_surveys")}</Text>
        ) : (
          surveys.map((survey) => (
            <Button
              key={survey.organization_survey_id}
              colorScheme="blue"
              w="full"
              onClick={() => navigate(`/hub/results/${survey.organization_survey_id}`)}
            >
              {t("hub.org_results.view_results")} "{survey.name}"
            </Button>
          ))
        )}
      </VStack>
    </Box>
  );
};

export default OrgResults;