import React from 'react';
import { Routes, Route } from 'react-router-dom';
import OrganizationList from './OrganizationList';
import OrganizationSurveys from './OrganizationSurveys';
import SurveyConfigurationPage from './SurveyConfigurationPage';
import OrganizationForm from './OrganizationForm';
import OrganizationView from './OrganizationView';
import OrganizationResults from './OrganizationResults';
import OrganizationUserForm from './OrganizationUserForm';
import ReportRoutes from '../report/report_routes';

const OrganizationRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<OrganizationList />} />
      <Route path="create" element={<OrganizationForm />} />
      <Route path=":id" element={<OrganizationView />} />
      <Route path=":id/edit" element={<OrganizationForm />} />
      <Route path=":organizationId/results" element={<OrganizationResults />} />
      <Route path=":organizationId/results/*" element={<ReportRoutes isHub={false} />} />
      <Route path=":organizationId/surveys" element={<OrganizationSurveys />} />
      <Route
        path=":organizationId/surveys/:surveyId/configure"
        element={<SurveyConfigurationPage />}
      />
      <Route path=":id/user/:action" element={<OrganizationUserForm />} />
    </Routes>
  );
};

export default OrganizationRoutes;