import React, { useState, useEffect } from "react";
import { 
  Box, Heading, Flex, Text, Table, Thead, Tbody, Tr, Th, Td, Spinner, Divider
} from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from "chart.js";
import { useParams } from "react-router-dom";
import axios from "../../axiosConfig";
import { CoverIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

// Register Chart Components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const DEC_HOUSES = 2;

const StabilityOverview = () => {
  const { t } = useTranslation();
  const { organizationSurveyId } = useParams();
  const [stabilityData, setStabilityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchStabilityData = async () => {
      try {
        const response = await axios.get(`/survey_reports/${organizationSurveyId}/stability_overview`);
        setStabilityData(response.data);
      } catch (err) {
        setError("Failed to load stability data.");
      } finally {
        setLoading(false);
      }
    };

    fetchStabilityData();
  }, [organizationSurveyId]);

  if (loading) return <Spinner size="xl" />;
  if (error) return <Text color="red.500">{error}</Text>;

  const getValidNumber = (value) => (typeof value === "number" && !isNaN(value) ? value : "N/A");

  // 📊 Prepare Column Chart (Stability Index by Role)
  const columnData = {
    labels: stabilityData.roles.map((role) => t(role.role)), 
    datasets: [
      {
        label: t("reports.stability.response_consistency"),
        data: stabilityData.roles.map((role) => role.stability_index),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      }
    ],
  };

  // 📊 Bar Chart - Stability Index per Area
  const barData = {
    labels: stabilityData.areas.map((area) => t(area.name)), 
    datasets: [
      {
        label: t("reports.stability.response_consistency"),
        data: stabilityData.areas.map((area) => area.stability_index),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
      {
        label: t("reports.stability.benchmark_consistency"),
        data: stabilityData.areas.map((area) => area.benchmark_stability_index),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  return (
    <Box>
      <Flex align="center" mb={4}>
        <CoverIcon />
        <Heading as="h1" size="2xl">{t("reports.cover.title")}</Heading>
      </Flex>
      <Text fontSize={{ base: "md", md: "2xl" }} color="gray.600" mb={2}>
        {t("reports.cover.survey")}: {stabilityData.surveyName}
      </Text>
      <Text fontSize={{ base: "md", md: "xl" }} color="gray.500" mb={4}>
        {t("reports.cover.organization")}: {stabilityData.organizationName}
      </Text>

      <Divider />
      
      {/* Overall Stability Index (OSI) */}
      <Box bg="gray.100" p={6} borderRadius="md" w="full" my={4}>
        <Text fontSize="2xl" fontWeight="bold">
          {t("reports.stability.overall_si")}: {getValidNumber(stabilityData.overallStabilityIndex)}
        </Text>
      </Box>

      <Divider />

      <Box maxW="700px" mx="auto">
        <Bar data={barData} />
      </Box>

      <Divider mt={4} />

      {/* 🔢 Stability Table - By Role */}
      <Box maxW="700px" mx="auto">
        <Table variant="striped" size="sm" mt={4}>
          <Thead>
            <Tr>
              <Th>{t("reports.stability.team")}</Th>
              <Th>{t("reports.stability.si")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {stabilityData.roles.map((role) => (
              <Tr key={role.role}>
                <Td>{t(role.role)}</Td>
                <Td>{getValidNumber(role.stability_index).toFixed(DEC_HOUSES)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default StabilityOverview;