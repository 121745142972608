import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Select,
  VStack,
  useToast,
  Text,
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from '../../axiosConfig';
import { useUser } from '../../contexts/UserContext';

const UserForm = ({
  initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    role: 'user',
    password: '',
    password_confirmation: '',
  },
}) => {
  const [formValues, setFormValues] = useState({ ...initialValues });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  // Roles available in the system
  const roles = [
    { value: 'tech', label: 'Tech' },
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' },
    { value: 'org_user', label: 'Organization User' },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value || '', // Ensure empty string for undefined/null
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrors({});
  
    // Ensure password confirmation matches if provided
    if (formValues.password && formValues.password !== formValues.password_confirmation) {
      setErrors({ password_confirmation: ['Passwords do not match'] });
      setIsSubmitting(false);
      return;
    }
  
    // Prepare data, remove password fields if empty
    const updatedValues = { ...formValues };
    if (!updatedValues.password) {
      delete updatedValues.password;
      delete updatedValues.password_confirmation;
    }
  
    try {
      if (initialValues.id) {
        await axios.put(`/users/${initialValues.id}`, { user: updatedValues });
        toast({
          title: 'Success',
          description: 'User updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        await axios.post('/users', { user: updatedValues });
        toast({
          title: 'Success',
          description: 'User created successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      navigate('/app/users');
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error',
        description: 'An unexpected error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Prevent rendering if `userData` is not available
  if (!user) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <VStack spacing={4} align="stretch">
        <FormControl isRequired>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            name="first_name"
            value={formValues.first_name}
            onChange={handleInputChange}
            isInvalid={errors.first_name}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Last Name</FormLabel>
          <Input
            type="text"
            name="last_name"
            value={formValues.last_name}
            onChange={handleInputChange}
            isInvalid={errors.last_name}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            isInvalid={errors.email}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Password {initialValues.id ? '(Leave blank to keep unchanged)' : ''}</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formValues.password || ''}
              onChange={handleInputChange}
              isInvalid={errors.password}
            />
            <InputRightElement>
              <IconButton
                icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? 'Hide Password' : 'Show Password'}
                variant="ghost"
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        {formValues.password && (
          <FormControl>
            <FormLabel>Password Confirmation</FormLabel>
            <InputGroup>
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                name="password_confirmation"
                value={formValues.password_confirmation || ''}
                onChange={handleInputChange}
                isInvalid={errors.password_confirmation}
              />
              <InputRightElement>
                <IconButton
                  icon={showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  aria-label={showConfirmPassword ? 'Hide Confirm Password' : 'Show Confirm Password'}
                  variant="ghost"
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        )}
        {formValues.role !== 'org_user' && (
          <FormControl>
            <FormLabel>Role</FormLabel>
            <Select
              name="role"
              value={formValues.role}
              onChange={handleInputChange}
              isDisabled={
                user.role !== 'tech' &&
                user.role !== 'admin' &&
                initialValues.id === user.id
              } // Disable if user attempts to change their own role
            >
              {roles.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        <Flex justifyContent="flex-end">
          <Button type="submit" colorScheme="green" variant="outline" isLoading={isSubmitting}>
            {initialValues.id ? 'Update User' : 'Create User'}
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default UserForm;