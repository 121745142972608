// Updated OrganizationView.jsx
// Includes: Colored Action Buttons, Billing + Notes in Collapsible Cards

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Text,
  VStack,
  HStack,
  Stack,
  Divider,
  Badge,
  useToast,
  useBreakpointValue,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Collapse,
} from '@chakra-ui/react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, BarController, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";
import { Chart } from "react-chartjs-2";
import { FaPencilAlt, FaClipboardList, FaChartBar, FaUser, FaEnvelope, FaFileInvoice, FaChevronDown, FaChevronUp, FaStickyNote } from 'react-icons/fa';
import axios from '../../axiosConfig';

ChartJS.register(CategoryScale, LinearScale, BarElement, BarController, LineElement, PointElement, Title, Tooltip, Legend);

const roleMap = {
  tech: 'Tech',
  admin: 'Admin',
  user: 'User',
  org_user: 'Organization User',
};

const OrganizationView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [organization, setOrganization] = useState(null);
  const [stats, setStats] = useState({ totalRespondents: 0, completedSurveys: 0 });
  const [surveyResults, setSurveyResults] = useState(null);
  const [user, setUser] = useState(null);
  const [showCharts, setShowCharts] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue("white", "gray.800");
  const sectionBg = useColorModeValue("gray.50", "gray.700");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [orgRes, statsRes, surveyRes, userRes] = await Promise.all([
          axios.get(`/organizations/${id}`),
          axios.get(`/organizations/${id}/stats`),
          axios.get(`/organizations/${id}/survey_results_by_page`),
          axios.get(`/organizations/${id}/user`).catch(e => e.response?.status === 404 ? null : Promise.reject(e))
        ]);
        setOrganization(orgRes.data);
        setStats(statsRes.data);
        setSurveyResults(surveyRes.data.surveys);
        setUser(userRes?.data || null);
      } catch (error) {
        console.error('Error loading organization view:', error);
        toast({ title: 'Error', description: 'Could not load organization details.', status: 'error', duration: 3000, isClosable: true });
        navigate('/app/organizations');
      }
    };
    fetchData();
  }, [id, navigate, toast]);

  const renderCharts = () => {
    if (!surveyResults) return <Text>Loading charts...</Text>;

    return surveyResults.map((survey, index) => {
      const labels = survey.pages.map(p => p.page_title);
      const scoreDistribution = {};
      survey.pages.forEach((page, idx) => {
        Object.entries(page.distribution).forEach(([score, count]) => {
          scoreDistribution[score] = scoreDistribution[score] || Array(labels.length).fill(0);
          scoreDistribution[score][idx] = count;
        });
      });
      const tableauColors = [
        "#4E79A7", // blue
        "#F28E2B", // orange
        "#E15759", // red
        "#76B7B2", // teal
        "#59A14F", // green
        "#EDC948", // yellow
        "#B07AA1", // purple
        "#FF9DA7", // pink
        "#9C755F", // brown
        "#BAB0AC"  // gray
      ];
      const barDatasets = Object.entries(scoreDistribution).map(([score, data], i) => ({
        label: `Score ${score}`,
        data,
        backgroundColor: tableauColors[i % tableauColors.length],
        yAxisID: "y",
        order: 2,
      }));
      const lineDataset = {
        label: "Average Score",
        data: survey.pages.map(p => p.average_score),
        type: "line",
        borderColor: "#0000FF", // "#0ea5e9",
        borderWidth: 2,
        pointBackgroundColor: "#0000FF", // "#0ea5e9",
        tension: 0.3,
        yAxisID: "y2",
        order: 1,
      };
      return (
        <Box key={index} bg={bgColor} p={6} shadow="md" borderRadius="md" mt={4} mb={6} width="100%" height="400px">
          <Heading size="md" mb={4}>{survey.survey_name}</Heading>
          <Chart type="bar" data={{ labels, datasets: [...barDatasets, lineDataset] }} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { display: true, position: "top" }, tooltip: { mode: "index", intersect: false } }, scales: { x: { stacked: true, title: { display: true, text: "Survey Pages" } }, y: { stacked: true, title: { display: true, text: "Response Count" } }, y2: { title: { display: true, text: "Average Score (1-7)" }, position: "right", min: 1, max: 7, grid: { drawOnChartArea: false } } } }} />
        </Box>
      );
    });
  };

  if (!organization) return <Text>Loading organization details...</Text>;

  return (
    <Box p={6} maxW="6xl" mx="auto">
      <Heading as="h2" size="lg" mb={4}>{organization.name}</Heading>

      <Card mb={6}><CardBody><Stack direction={isMobile ? 'column' : 'row'} spacing={4}>
        <Button leftIcon={<FaPencilAlt />} colorScheme="yellow" variant="outline" as={Link} to={`/app/organizations/${id}/edit`}>Edit</Button>
        <Button leftIcon={<FaClipboardList />} colorScheme="cyan" variant="outline" as={Link} to={`/app/organizations/${id}/surveys`}>Manage Surveys</Button>
        <Button leftIcon={<FaChartBar />} colorScheme="teal" variant="outline" as={Link} to={`/app/organizations/${id}/results`}>Survey Results</Button>
        <Button leftIcon={<FaUser />} colorScheme="blue" variant="outline" as={Link} to={`/app/organizations/${id}/user/${user ? 'edit' : 'create'}`}>{user ? 'Edit User' : 'Add User'}</Button>
      </Stack></CardBody></Card>

      <Card mb={6}><CardHeader><Heading size="md">General Information</Heading></CardHeader><CardBody>
        <VStack align="start" spacing={2}>
          <Text><strong>Contact:</strong> {organization.contact} {organization.title ? `(${organization.title})` : ''}</Text>
          <Text><strong>Email:</strong> {organization.email}</Text>
          <Text><strong>Phone:</strong> {organization.phone}</Text>
          <Text><strong>Address:</strong> {organization.address}, {organization.city}, {organization.state} {organization.zip}, {organization.country}</Text>
        </VStack>
      </CardBody></Card>

      <Card mb={6}><Accordion allowToggle><AccordionItem>
        <AccordionButton _expanded={{ bg: sectionBg }}><Box flex="1" textAlign="left"><Icon as={FaFileInvoice} mr={2} />Billing Information</Box><AccordionIcon /></AccordionButton>
        <AccordionPanel pb={4}>
          <VStack align="start" spacing={2}>
            <Text><strong>Company:</strong> {organization.billing_company_name}</Text>
            <Text><strong>VAT / Tax:</strong> {organization.vat_or_tax_number}</Text>
            <Text><strong>Billing Method:</strong> {organization.billing_method}</Text>
            {organization.billing_email && <Text><strong>Email:</strong> {organization.billing_email}</Text>}
            {organization.billing_method_other && <Text><strong>Other:</strong> {organization.billing_method_other}</Text>}
            <Text><strong>Currency:</strong> {organization.currency}</Text>
            <Text><strong>Reference:</strong> {organization.reference_person} / {organization.reference_number}</Text>
            <Text><strong>Cost Center:</strong> {organization.cost_center}</Text>
            {organization.billing_notes && <Text><strong>Notes:</strong> {organization.billing_notes}</Text>}
            {!organization.billing_same_as_org && (
              <Text><strong>Billing Address:</strong> {organization.billing_address}, {organization.billing_city}, {organization.billing_state} {organization.billing_zip}, {organization.billing_country}</Text>
            )}
          </VStack>
        </AccordionPanel>
      </AccordionItem></Accordion></Card>

      
      <Card mb={6}><Accordion allowToggle><AccordionItem>
        <AccordionButton _expanded={{ bg: sectionBg }}><Box flex="1" textAlign="left"><Icon as={FaStickyNote} mr={2} />General Notes</Box><AccordionIcon /></AccordionButton>
        <AccordionPanel pb={4}>
          {organization.notes ? (
            <Text whiteSpace="pre-wrap">{organization.notes}</Text>
          ) : (
            <Text fontStyle="italic" color="gray.500">There are no notes for this organization yet.</Text>
          )}
        </AccordionPanel>
      </AccordionItem></Accordion></Card>

      <Card mb={6}><CardHeader><Heading size="md">Organization Stats</Heading></CardHeader><CardBody>
        <VStack spacing={2} align="start">
          <Text><strong>Total Respondents:</strong> {stats.totalRespondents}</Text>
          <Text><strong>Completed Surveys:</strong> {stats.completedSurveys}</Text>
        </VStack>
      </CardBody></Card>

      <Button mb={4} onClick={() => setShowCharts((prev) => !prev)} leftIcon={showCharts ? <FaChevronUp /> : <FaChevronDown />} variant="outline" colorScheme="blue" fontWeight="normal">
        {showCharts ? 'Hide Survey Charts' : 'Show Survey Charts'}
      </Button>
      <Collapse in={showCharts} animateOpacity>{renderCharts()}</Collapse>

      {user && (
        <Card mt={6}><CardHeader><Heading size="md">Associated User</Heading></CardHeader><CardBody>
          <VStack align="start" spacing={2}>
            <Text fontWeight="bold">{user.first_name} {user.last_name}</Text>
            <HStack><Icon as={FaEnvelope} /><Text>{user.email}</Text></HStack>
            <Badge colorScheme="blue">{roleMap[user.role]}</Badge>
          </VStack>
        </CardBody></Card>
      )}
    </Box>
  );
};

export default OrganizationView;
