import { useUser } from "../../../contexts/UserContext";
import OrganizationForm from "../../organization/OrganizationForm";
import { useTranslation } from "react-i18next";

const OrgSettings = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  // Safely extract the organization ID
  const orgId = user?.organization_id || null;

  return orgId ? <OrganizationForm id={orgId} /> : <p>{t("organization.loading")}</p>;
};

export default OrgSettings;