import React, { useState, useEffect } from "react";
import { 
  Box, Flex, Heading, Text, Stack, Card, CardHeader, CardBody, Spinner, Divider
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "../../axiosConfig";
import { getAreaName } from "../../utils/areaNames";
import { DevelopmentIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

const Development = ({ isHub }) => {
  console.log(`Loading Development Page with Hub: '${isHub}'`);
  const { t } = useTranslation();
  const { organizationSurveyId } = useParams();
  const [developmentData, setDevelopmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchDevelopment = async () => {
      try {
        const response = await axios.get(`/survey_reports/${organizationSurveyId}/development`);
        setDevelopmentData(response.data);
      } catch (err) {
        setError(t("reports.development.error"));
      } finally {
        setLoading(false);
      }
    };

    fetchDevelopment();
  }, [organizationSurveyId, t]);

  if (loading) return <Spinner size="xl" />;
  if (error) return <Text color="red.500">{error}</Text>;

  return (
    <Box>
      <Flex align="center" mb={4}>
        <DevelopmentIcon />
        <Heading as="h1" size="2xl" color="#e5923b" ml={3}>
          {t("reports.development.title")}
        </Heading>
      </Flex>

      <Text fontSize="xl" color="gray.500" mb={4}>
        {t("reports.development.description")}
      </Text>
      <Divider />

      {/* Highlighted Development Area */}
      <Box bg="#fbe4d5" p={6} borderRadius="md" w="full" mt={4}>
        <Text fontSize="2xl" fontWeight="bold" color="#e5923b">
          {getAreaName(developmentData.lowest_area)}
        </Text>
      </Box>

      <Divider />

      {/* Two Weakest Factors */}
      <Heading as="h3" size="lg" mt={4}>
        {t("reports.development.focus_factors")}
      </Heading>
      <Stack spacing={4} w="full">
        {developmentData.bottom_factors.map((factor, index) => (
          <Flex
            key={index}
            direction={{ base: "column", md: "row" }}
            gap={4}
          >
            <Card flex="1" shadow="md" borderRadius="md" p={4} bg="gray.50">
              <CardHeader>
                <Heading size="md" color="#e5923b">
                  {factor.factor}
                </Heading>
              </CardHeader>
              <CardBody>
                <Text>{factor.description}</Text>
              </CardBody>
            </Card>
            <Card flex="1" shadow="md" borderRadius="md" p={4} bg="gray.50">
              <CardBody>
                {/* Bullet list section for bottom 3 questions */}
                {factor.questions && factor.questions.length > 0 && (
                  <Box>
                    <Box as="ul" pl={4}>
                      {factor.questions.slice(0, 3).map((question, qIndex) => (
                        <Box as="li" key={qIndex}>
                          {question.text}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </CardBody>
            </Card>
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};

export default Development;